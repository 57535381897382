import { default as dashboardvdohHtnsuEMeta } from "/home/forge/productions.smake.cloud/releases/v0.2.0/pages/[hash]/dashboard.vue?macro=true";
import { default as entrancebgKmvAgfgqMeta } from "/home/forge/productions.smake.cloud/releases/v0.2.0/pages/[hash]/entrance.vue?macro=true";
import { default as indexZ9o6A4WciTMeta } from "/home/forge/productions.smake.cloud/releases/v0.2.0/pages/[hash]/index.vue?macro=true";
import { default as login31fhDYwnghMeta } from "/home/forge/productions.smake.cloud/releases/v0.2.0/pages/[hash]/login.vue?macro=true";
export default [
  {
    name: "hash-dashboard",
    path: "/:hash()/dashboard",
    component: () => import("/home/forge/productions.smake.cloud/releases/v0.2.0/pages/[hash]/dashboard.vue").then(m => m.default || m)
  },
  {
    name: entrancebgKmvAgfgqMeta?.name ?? "hash-entrance",
    path: "/:hash()/entrance",
    meta: entrancebgKmvAgfgqMeta || {},
    component: () => import("/home/forge/productions.smake.cloud/releases/v0.2.0/pages/[hash]/entrance.vue").then(m => m.default || m)
  },
  {
    name: "hash",
    path: "/:hash()",
    component: () => import("/home/forge/productions.smake.cloud/releases/v0.2.0/pages/[hash]/index.vue").then(m => m.default || m)
  },
  {
    name: login31fhDYwnghMeta?.name ?? "hash-login",
    path: "/:hash()/login",
    meta: login31fhDYwnghMeta || {},
    component: () => import("/home/forge/productions.smake.cloud/releases/v0.2.0/pages/[hash]/login.vue").then(m => m.default || m)
  }
]